import { Document, Page, pdfjs } from "react-pdf";
import adriResume from "./media/adriResume.pdf";
import adriResumeIMG from "./media/adriResume.png";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Resume() {
  return (
    <div className="mb-5">
      <div className="flex justify-center items-center align-center">
        <Document file={adriResume} className="hidden md:block">
          <Page pageNumber={1} />
        </Document>
        <img src={adriResumeIMG} alt="resume" className="md:hidden w-5/6" />
      </div>
    </div>
  );
}
