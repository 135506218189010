import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "./media/adrianneLogo.png";

export default function Header() {
  const [page, setPage] = useState("");

  const pageSet = (page) => {
    setPage(page);
  };

  useEffect(() => {
    console.log(page);
  }, [page]);

  return (
    <nav className="flex flex-col items-center bg-transparent w-screen justify-center my-2">
      <div className="flex items-center justify-center w-10/12 md:w-4/12">
        <img src={logo} />
      </div>
      <div className="space-x-2 md:space-x-9">
        <Link
          to="/"
          onClick={() => pageSet("About")}
          className={page === "About" ? "text-gray-500" : "text-white"}
        >
          About
        </Link>
        <Link
          to="/projects"
          onClick={() => pageSet("Projects")}
          className={page === "Projects" ? "text-gray-500" : "text-white"}
        >
          Projects
        </Link>
        <Link
          to="/resume"
          onClick={() => pageSet("Resume")}
          className={page === "Resume" ? "text-gray-500" : "text-white"}
        >
          Resume
        </Link>
        <Link
          to="/contact"
          onClick={() => pageSet("Contact")}
          className={page === "Contact" ? "text-gray-500" : "text-white"}
        >
          Contact
        </Link>
      </div>
    </nav>
  );
}
