import ReactPlayer from "react-player";
import axios from "axios";
import { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Videos() {
  const [vids, getVids] = useState({});
  const [loading, isLoading] = useState(false);

  useEffect(() => {
    axios
      .get(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=50&playlistId=UUX6b8BGg_Eo42lmWsUDIF4w&key=AIzaSyBzWEev870TN2EdGzXCEQCHrCWD32KqEE0`
      )
      .then((res) => {
        const videos = res.data.items;
        console.log(videos);
        videos.map((video) => {
          var date = new Date(video.snippet.publishedAt);
          var year = date.getFullYear();
          video.snippet.publishedAtYear = year;
        });
        return videos;
      })
      .then((res) => {
        getVids(res);
        isLoading(true);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    console.log(vids);
  }, [vids]);

  return loading ? (
    <div className="">
      <p className="flex justify-center place-content-center text-3xl">
        Events
      </p>
      <div className="flex flex-column justify-center place-content-center max-w-screen-sm md:max-w-none md:flex-col flex-wrap w-screen overflow-hidden md:px-5">
        <div div className="w-3/4 md:w-5/12 mt-5 mx-3">
          <Carousel showArrows={true} showThumbs={false}>
            <div>
              <img src="media/events/MSATL/1.jpeg" />
            </div>
            <div>
              <img src="media/events/MSATL/2.jpeg" />
            </div>
            <div>
              <img src="media/events/MSATL/3.jpeg" />
            </div>
          </Carousel>
          <div className="flex flex-col">
            <div>Microsoft ATL Launch</div>
            <div>Event Coordinator</div>
            <div>2022</div>
          </div>
        </div>
        <br />
        <div div className="w-3/4 md:w-5/12 mt-5 mx-3">
          <Carousel showArrows={true} showThumbs={false}>
            <div>
              <img src="media/events/MBP/1.jpg" />
            </div>
            <div>
              <img src="media/events/MBP/2.jpg" />
            </div>
            <div>
              <img src="media/events/MBP/3.jpg" />
            </div>
            <div>
              <img src="media/events/MBP/4.jpg" />
            </div>
            <div>
              <img src="media/events/MBP/5.jpg" />
            </div>
            <div>
              <img src="media/events/MBP/6.jpg" />
            </div>
            <div>
              <img src="media/events/MBP/7.jpg" />
            </div>
          </Carousel>
          <div className="flex flex-col">
            <div>MBP Events</div>
            <div>Personal Assistant</div>
            <div>2020-2021</div>
          </div>
        </div>
      </div>
      <p className="flex justify-center place-content-center text-3xl">
        Video Content
      </p>
      <div className="flex flex-row justify-center place-content-center max-w-screen-sm md:max-w-none md:flex-row flex-wrap w-screen overflow-hidden md:px-5">
        {vids.map((video) => (
          <>
            <div className="md:flex hidden flex-col m-5 justify-center flex-shrink">
              <div className="">
                <ReactPlayer
                  url={`https://www.youtube.com/watch?v=${video.contentDetails.videoId}`}
                  light={true}
                  controls={true}
                />
              </div>
              <div className="flex flex-col">
                <div>{video.snippet.title}</div>
                <div>{video.snippet.publishedAtYear}</div>
              </div>
            </div>

            <div className="flex flex-col m-0 my-2 md:hidden">
              <ReactPlayer
                url={`https://www.youtube.com/watch?v=${video.contentDetails.videoId}`}
                width="100%"
                height="100%"
                controls={true}
              />
              <div className="flex flex-col">
                <div>{video.snippet.title}</div>
                <div>{video.snippet.publishedAtYear}</div>
              </div>
            </div>
          </>
        ))}
      </div>

      <div className="flex flex-row justify-center place-content-center max-w-screen-sm md:max-w-none md:flex-row flex-wrap w-screen overflow-hidden md:px-5">
        <div className="md:flex hidden flex-col m-5 justify-center flex-shrink">
          <div className="">
            <ReactPlayer url={"media/gladysnight.mp4"} controls={true} />
          </div>
          <div className="flex flex-col">
            <div>"Gladys Knight" Music Video</div>
            <div>Producer</div>
            <div>Jocose</div>
          </div>
        </div>

        <div className="flex flex-col w-9/12 my-2 md:hidden">
          <ReactPlayer
            url={"media/gladysnight.mp4"}
            width="100%"
            height="100%"
            controls={true}
          />
          <div className="flex flex-col">
            <div>"Gladys Knight" Music Video</div>
            <div>Producer</div>
            <div>Jocose</div>
          </div>
        </div>

        <div className="w-3/4 md:w-5/12 mt-5">
          <Carousel showArrows={true} showThumbs={false}>
            <div>
              <img src="media/agentsofchaos/1.jpg" />
            </div>
            <div>
              <img src="media/agentsofchaos/2.jpg" />
            </div>
            <div>
              <img src="media/agentsofchaos/3.jpg" />
            </div>
            <div>
              <img src="media/agentsofchaos/4.jpg" />
            </div>
          </Carousel>
          <div className="flex flex-col">
            <div>"AGENTS OF CHAOS" Poster Series</div>
            <div>Producer</div>
            <div>Jocose</div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
