export default function Contact() {
  return (
    <div className="flex flex-col justify-center items-center align-center my-20 text-lg md:text-4xl mx-3">
      Please feel free to reach out to me! :)
      <br />
      <br />
      <div>
        Email:{" "}
        <a
          href="mailto:dowdyadrianne@gmail.com"
          className="underline underline-offset-2"
        >
          dowdyadrianne@gmail.com
        </a>
      </div>
      <br />
      <div>
        <a
          href="https://www.linkedin.com/in/adrianne-dowdy-068591173/"
          className="underline underline-offset-2"
        >
          LinkedIn
        </a>
      </div>
    </div>
  );
}
