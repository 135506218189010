import Adri from "./media/Adrianne.JPEG";

export default function About() {
  return (
    <div className="flex justify-center align-center flex-grow md:mx-32">
      <div className="flex md:space-x-10 flex-col md:flex-row items-center align-center justify-center w-screen">
        <img
          src={Adri}
          className="w-1/3 rounded-tr-full rounded-tl-full rounded-bl-md rounded-br-md"
        />
        <div className="flex flex-col items-center align-center justify-center md:w-1/2">
          <div className="text-5xl font-bold mb-3">About Me</div>
          <div className="text-justify text-xl px-5 font-sans">
            I am a Georgia State alumna who received a B.A in Film and Media,
            and a minor in Women’s, Gender, and Sexuality Studies.
            <br />
            <br />
            I have professional experience working as a producer, event
            coordinator, personal assistant, and social media manager. Each day
            I work to continue expanding my knowledge in all aspects of the film
            and entertainment industry. I have a passion for overseeing all
            background coordination of the production process, to ensure all
            productions come to life in a dynamic way.
            <br />
            <br />
            My passion for film began at Douglas County High School, this is
            where I served as a videographer for my Senior IB class. From then,
            I knew I was meant to help capture moments that will last for
            eternity. This journey then continued in college; where I learned
            how to film, direct, produce and edit my own short films.
            <br />
            <br />
            In my free time, I enjoy assisting my friends with scriptwriting,
            making TikToks, journaling, painting, traveling, and attending
            concerts. Nevertheless, I am forever in touch with my creative side
            and want to gain as much experience as possible in the entertainment
            industry.
          </div>
        </div>
      </div>
    </div>
  );
}
